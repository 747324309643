import { createSlice } from "@reduxjs/toolkit";
import { GetDashboardCommentsListAction, GetDashboardDataAction, GetDashboardRestaurantsListAction } from "./action";

export const initialState = {
  dashboardData: {
    loading: false,
    data: null,
  },
  dashboardCommentList: {
    loading: false,
    data: null,
  },
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //dashboard data get reducer
    builder.addCase(GetDashboardDataAction.pending, (state) => {
      state.dashboardData.loading = true;
    });
    builder.addCase(GetDashboardDataAction.fulfilled, (state, action) => {
      state.dashboardData.loading = false;
      state.dashboardData.data = action.payload.data;
    });
    builder.addCase(GetDashboardDataAction.rejected, (state) => {
      state.dashboardData.loading = false;
      state.dashboardData.data = {};
    });
    //dashboard comment list reducer
    builder.addCase(GetDashboardCommentsListAction.pending, (state) => {
      state.dashboardCommentList.loading = true;
    });
    builder.addCase(GetDashboardCommentsListAction.fulfilled, (state, action) => {
      state.dashboardCommentList.loading = false;
      state.dashboardCommentList.data = action.payload.data;
    });
    builder.addCase(GetDashboardCommentsListAction.rejected, (state) => {
      state.dashboardCommentList.loading = false;
      state.dashboardCommentList.data = [];
    });
  },
});

export default dashboardSlice;
