// third-party
import { combineReducers } from "redux";
import adminSlice from "../Admin/reducer";
import authSlice from "../Auth/reducer";
import customerSlice from "../Customer/reducer";
import dashboardSlice from "../Dashboard/reducer";
import profileSlice from "../Profile/reducer";
import restaurantSlice from "../Restaurant/reducer";
import settingSlice from "../Settings/reducer";

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  auth: authSlice.reducer,
  profile: profileSlice.reducer,
  admin: adminSlice.reducer,
  setting: settingSlice.reducer,
  dashboard: dashboardSlice.reducer,
  restaurant: restaurantSlice.reducer,
  customer: customerSlice.reducer,
});

export default reducers;
