// material-ui
import { useTheme } from "@mui/material/styles";
import { AppBar, Toolbar, Container, Box } from "@mui/material";

// project import
import HeaderContent from "./HeaderContent";

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = () => {
  const theme = useTheme();

  // common header
  const mainHeader = (
    <Toolbar sx={{ position: "relative" }}>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          opacity: 0.7,
          backgroundImage: "linear-gradient(to left, red ,white)",
        }}
      ></Box>
      <Container className="header-cover" maxWidth="lg" sx={{ display: "flex", width: "100%" }}>
        <HeaderContent />
      </Container>
    </Toolbar>
  );

  // app-bar params
  const appBar = {
    position: "fixed",
    elevation: 0,
    sx: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      background: "transparent",
    },
  };

  return (
    <>
      <AppBar {...appBar}>{mainHeader}</AppBar>
    </>
  );
};

export default Header;
