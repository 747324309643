import { createSlice } from "@reduxjs/toolkit";
import { ForgotPasswordAction, LoginAction, RegisterAction, ResetPasswordAction } from "./action";

export const initialState = {
  register: {
    loading: false,
    error: null,
  },
  login: {
    loading: false,
    error: null,
  },
  forgotPassword: {
    loading: false,
    error: null,
  },
  resetPassword: {
    loading: false,
    error: null,
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    //register-error-reset
    ResetRegisterError(state, action) {
      if (action?.payload?.name) {
        state.register.error[action.payload.name] = null;
      } else {
        state.register.error = null;
      }
    },
    //login-error-reset
    ResetLoginError(state, action) {
      if (action?.payload?.name) {
        state.login.error[action.payload.name] = null;
      } else {
        state.login.error = null;
      }
    },
    //reset-forgot-password-error
    ResetForgotPswError(state) {
      state.forgotPassword.error = null;
    },
    //reset-psw-reset-error
    ResetPswResetError(state) {
      state.resetPassword.error = null;
    },
  },
  extraReducers: (builder) => {
    //register reducer
    builder.addCase(RegisterAction.pending, (state) => {
      state.register.loading = true;
    });
    builder.addCase(RegisterAction.fulfilled, (state) => {
      state.register.loading = false;
      state.register.error = null;
    });
    builder.addCase(RegisterAction.rejected, (state, action) => {
      state.register.loading = false;
      state.register.error = action.payload.errors;
    });

    //login reducer
    builder.addCase(LoginAction.pending, (state) => {
      state.login.loading = true;
    });
    builder.addCase(LoginAction.fulfilled, (state) => {
      state.login.loading = false;
      state.login.error = null;
    });
    builder.addCase(LoginAction.rejected, (state, action) => {
      state.login.loading = false;
      state.login.error = action.payload;
    });

    //Forgot Password reducer
    builder.addCase(ForgotPasswordAction.pending, (state) => {
      state.forgotPassword.loading = true;
    });
    builder.addCase(ForgotPasswordAction.fulfilled, (state) => {
      state.forgotPassword.loading = false;
      state.forgotPassword.error = null;
    });
    builder.addCase(ForgotPasswordAction.rejected, (state, action) => {
      state.forgotPassword.loading = false;
      state.forgotPassword.error = action.payload;
    });

    // reset password reducer
    builder.addCase(ResetPasswordAction.pending, (state) => {
      state.resetPassword.loading = true;
    });
    builder.addCase(ResetPasswordAction.fulfilled, (state) => {
      state.resetPassword.loading = false;
      state.resetPassword.error = null;
    });
    builder.addCase(ResetPasswordAction.rejected, (state, action) => {
      state.resetPassword.loading = false;
      state.resetPassword.error = action.payload.errors;
    });
  },
});

export default authSlice;
