// material-ui
import { Box } from "@mui/material";

// project import
import Profile from "./Profile";
import LogoSection from "src/components/Logo/index";
import LanguageTranslation from "./LangaugeTranslation/LanguageTranslation";
// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  return (
    <>
      <Box sx={{ width: "100%", ml: 1, display: "flex", alignItems: "center" }}>
        <LogoSection />
      </Box>
      <Box
        sx={{
          width: "100%",
          ml: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <LanguageTranslation style={{width:"100px"}} />
        <Profile />
      </Box>
    </>
  );
};

export default HeaderContent;
