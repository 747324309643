import { lazy } from "react";

// project import
import PrivateRoute from "./PrivateRoute";
import { Navigate } from "react-router";
import Loadable from "src/components/Loadable";
import MainLayout from "src/layout/MainLayout";

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import("src/pages/dashboard")));
const Profile = Loadable(lazy(() => import("src/pages/profile")));
const EditProfile = Loadable(lazy(() => import("src/pages/profile/EditProfile")));
const ChangePassword = Loadable(lazy(() => import("src/pages/settings/ChangePassword")));
const MasterSetting = Loadable(lazy(() => import("src/pages/settings/MasterSetting")));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: (
    <PrivateRoute>
      <MainLayout />
    </PrivateRoute>
  ),
  children: [
    {
      path: "/",
      element: <DashboardDefault />,
    },
    {
      path: "profile",
      element: <Profile />,
    },
    {
      path: "edit-profile",
      element: <EditProfile />,
    },
    {
      path: "password/change",
      element: <ChangePassword />,
    },
    {
      path: "master-setting",
      element: <MasterSetting />,
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ],
};

export default MainRoutes;
