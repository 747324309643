// material-ui
import { useTheme } from "@mui/material/styles";
import { List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

// assets
import { KeyOutlined, LockOutlined, QuestionCircleOutlined, ToolOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

// ==============================|| HEADER PROFILE - SETTING TAB ||============================== //

const SettingTab = (props) => {
  const { handleToggle, data } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { pathname } = useLocation();

  return (
    <List component="nav" sx={{ p: 0, "& .MuiListItemIcon-root": { minWidth: 32, color: theme.palette.grey[500] } }}>
      {/* <ListItemButton
        selected={pathname === "/master-setting"}
        onClick={() => {
          navigate("/master-setting");
          handleToggle();
        }}
      >
        <ListItemIcon>
          <KeyOutlined />
        </ListItemIcon>
        <ListItemText primary={t("master setting")} />
      </ListItemButton> */}
      <ListItemButton
        selected={pathname === "/password/change"}
        onClick={() => {
          navigate("/password/change");
          handleToggle();
        }}
      >
        <ListItemIcon>
          <KeyOutlined />
        </ListItemIcon>
        <ListItemText primary={t("changePassword")} />
      </ListItemButton>
      {(data?.permission?.includes("view_permission") || data?.roles === "Admin") && (
        <ListItemButton
          selected={pathname === "/permission-management"}
          onClick={() => {
            navigate("/permission-management");
            handleToggle();
          }}
        >
          <ListItemIcon>
            <ToolOutlined />
          </ListItemIcon>
          <ListItemText primary={t("Manage Permissions")} />
        </ListItemButton>
      )}
      <ListItemButton
        selected={pathname === "/support"}
        onClick={() => {
          navigate("/client-support");
          handleToggle();
        }}
      >
        <ListItemIcon>
          <QuestionCircleOutlined />
        </ListItemIcon>
        <ListItemText primary={t("Support")} />
      </ListItemButton>
      <ListItemButton
        selected={pathname === "/privacy"}
        onClick={() => {
          navigate("/privacy-policy");
          handleToggle();
        }}
      >
        <ListItemIcon>
          <LockOutlined />
        </ListItemIcon>
        <ListItemText primary={t("Privacy Policy")} />
      </ListItemButton>
    </List>
  );
};

export default SettingTab;
