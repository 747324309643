import appUtils from "src/utils/appUtils";
import apiInterceptor from "src/utils/apiUtils/apiInterceptor";
import { createAsyncThunk } from "@reduxjs/toolkit";

//Get dashboard data Action
export const GetDashboardDataAction = createAsyncThunk(
  "dashboard/GetDashboardDataAction",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiInterceptor.get(process.env.REACT_APP_BASE_URL+`/api/dashboard`, appUtils.getAuthHeader());
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//Dashboard Comments List Action
export const GetDashboardCommentsListAction = createAsyncThunk(
  "dashboard/GetDashboardCommentsListAction",
  async (data, { rejectWithValue }) => {
    const { page } = data;
    try {
      const response = await apiInterceptor.get(process.env.REACT_APP_BASE_URL+`/api/comment?page=${page}`, appUtils.getAuthHeader());
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
