import React from "react";

import { createRoot } from "react-dom/client";
// project import
import App from "./App";

import reportWebVitals from "./reportWebVitals";
import "react-toastify/dist/ReactToastify.min.css";
// ==============================|| MAIN - REACT DOM RENDER  ||============================== //
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
