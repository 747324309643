import appUtils from "src/utils/appUtils";
import apiInterceptor from "src/utils/apiUtils/apiInterceptor";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import i18n from "src/i18n";
import { format } from "date-fns";

//Get Employees List Action
export const GetEmployeesListAction = createAsyncThunk(
  "restaurant/GetEmployeesListAction",
  async (data, { rejectWithValue }) => {
    const { page } = data;
    try {
      const response = await apiInterceptor.get(process.env.REACT_APP_BASE_URL+`/api/auth/waiter?page=${page}`, appUtils.getAuthHeader());
      return { response: response.data, page: page };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//Get Employees Detail Action
export const GetEmployeesDetailAction = createAsyncThunk(
  "restaurant/GetEmployeesDetailAction",
  async (data, { rejectWithValue }) => {
    const { id } = data;
    try {
      const response = await apiInterceptor.get(process.env.REACT_APP_BASE_URL+`/api/auth/waiter/${id}`, appUtils.getAuthHeader());
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//Add Employee Action
export const AddEmployeeAction = createAsyncThunk("restaurant/AddEmployeeAction", async (data, { rejectWithValue }) => {
  const { FormData, setOpen, formAction, setImageUrl } = data;
  try {
    const formData = appUtils.getFormData(FormData);
    const response = await apiInterceptor.post(process.env.REACT_APP_BASE_URL+`/api/auth/waiter`, formData, appUtils.getAuthHeader());
    toast.success(i18n.t("Employee Added Successfully"));
    formAction.resetForm();
    setImageUrl("");
    setOpen(false);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

//Edit Employee Action
export const EmployeeEditAction = createAsyncThunk(
  "restaurant/EmployeeEditAction",
  async (data, { rejectWithValue }) => {
    const { FormData, navigate, id,lang } = data;
    const formData = appUtils.getAllFormData(FormData);
    try {
      const response = await apiInterceptor.post(process.env.REACT_APP_BASE_URL+`/api/auth/waiter/${id}/${lang}`, formData, appUtils.getAuthHeader());
      navigate(-1);
      toast.success(i18n.t("Employee detail edited successfully"));
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//Delete Employee Action
export const EmployeeDeleteAction = createAsyncThunk(
  "restaurant/EmployeeDeleteAction",
  async (data, { rejectWithValue }) => {
    const { setOpen, id } = data;
    try {
      await apiInterceptor.delete(process.env.REACT_APP_BASE_URL+`/api/auth/waiter/${id}`, appUtils.getAuthHeader());
      setOpen();
      toast.success(i18n.t("Employee data deleted successfully"));
      return { id: id };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//Comments List Action
export const GetCommentsListAction = createAsyncThunk(
  "restaurant/GetCommentsListAction",
  async (data, { rejectWithValue }) => {
    const { page, selected_depart, selected_staff, num } = data;
    try {
      // if(selected_depart !=""){
      //   console.log('1')
      //   var url = process.env.REACT_APP_BASE_URL+`/api/comment?num=${num}&selected_depart=${selected_depart}&page=${page}`;
      // }else if(selected_staff !=""){
      //   console.log('2')
      //   var url = process.env.REACT_APP_BASE_URL+`/api/comment?num=${num}&selected_staff=${selected_staff}&page=${page}`;
      // } else {
      //   console.log('3')
      //   var url = process.env.REACT_APP_BASE_URL+`/api/comment?num=${num}&page=${page}`;
      // }
      var url = process.env.REACT_APP_BASE_URL+`/api/comment?num=${num}&selected_depart=${selected_depart}&selected_staff=${selected_staff}&page=${page}`;
      const response = await apiInterceptor.get(url, appUtils.getAuthHeader());
      return { response: response.data, page: page };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//Search Employees Action
export const SearchEmployeesAction = createAsyncThunk(
  "restaurant/SearchEmployeesAction",
  async (data, { rejectWithValue }) => {
    const { query } = data;
    try {
      const response = await apiInterceptor.get(process.env.REACT_APP_BASE_URL+`/api/auth/waiter?search=${query}`, appUtils.getAuthHeader());
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//Restaurant Tips Get Action
export const GetTipsAction = createAsyncThunk("restaurant/GetTipsAction", async (data, { rejectWithValue }) => {
  const { date, setFilterResultDate } = data;
  const year = format(new Date(date), "yyyy");
  const month = format(new Date(date), "MM");
  try {
    const response = await apiInterceptor.get(
      process.env.REACT_APP_BASE_URL+`/api/tip-collected?year=${year}&month=${month}`,
      appUtils.getAuthHeader()
    );
    setFilterResultDate(date);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});
// 月のチップ合計
export const GetDashboardDataTotalMoneyAction = createAsyncThunk("restaurant/GetDashboardDataTotalMoneyAction", async (data, { rejectWithValue }) => {
  const { date, setFilterResultDate } = data;
  const year = format(new Date(date), "yyyy");
  const month = format(new Date(date), "MM");
  try {
    const response = await apiInterceptor.get(
      process.env.REACT_APP_BASE_URL+`/api/total-tip-collected?year=${year}&month=${month}`,
      appUtils.getAuthHeader()
    );
    setFilterResultDate(date);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});
// 月のポイント合計
export const GetDashboardDataTotalPointAction = createAsyncThunk("restaurant/GetDashboardDataTotalPointAction", async (data, { rejectWithValue }) => {
  const { date, setFilterResultDate } = data;
  const year = format(new Date(date), "yyyy");
  const month = format(new Date(date), "MM");
  try {
    const response = await apiInterceptor.get(
      process.env.REACT_APP_BASE_URL+`/api/total-point-collected?year=${year}&month=${month}`,
      appUtils.getAuthHeader()
    );
    setFilterResultDate(date);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

//Get Department List Action
export const GetDepartmentListAction = createAsyncThunk(
  "restaurant/GetDepartmentListAction",
  async (data, { rejectWithValue }) => {
    // const { page } = data;
    try {
      // const response = await apiInterceptor.get(process.env.REACT_APP_BASE_URL+`/api/auth/department?page=${page}`, appUtils.getAuthHeader());
      const response = await apiInterceptor.get(process.env.REACT_APP_BASE_URL+`/api/auth/department`, appUtils.getAuthHeader());
      return { response: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const GetTranslateStaffAction = createAsyncThunk(
  "restaurant/GetTranslateStaffAction",
  async (data, { rejectWithValue }) => {
    // const { page } = data;
    try {
      const response = await apiInterceptor.get(process.env.REACT_APP_BASE_URL+`/api/auth/translate_staff/${data}`, appUtils.getAuthHeader());
      return { response: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const GetTranslateDepartmentAction = createAsyncThunk(
  "restaurant/GetTranslateDepartmentAction",
  async (data, { rejectWithValue }) => {
    // const { page } = data;
    try {
      const response = await apiInterceptor.get(process.env.REACT_APP_BASE_URL+`/api/auth/translate_department/${data}`, appUtils.getAuthHeader());
      return { response: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//Add Department Action
export const AddDepartmentAction = createAsyncThunk(
  "restaurant/AddDepartmentAction",
  async (data, { rejectWithValue }) => {
    const { FormData, setOpen, formAction } = data;
    try {
      const formData = appUtils.getFormData(FormData);
      const response = await apiInterceptor.post(process.env.REACT_APP_BASE_URL+`/api/auth/department`, formData, appUtils.getAuthHeader());
      toast.success(i18n.t("Successfully added departments"));
      formAction.resetForm();
      setOpen(false);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
//Edit Department Action
// export const DepartmentEditAction = createAsyncThunk(
//   "restaurant/DepartmentEditAction",
//   async (data, { rejectWithValue }) => {
//     // const { FormData, id, setOpen, formAction,lang } = data;
//     const { FormData, id ,lang } = data;

//     const formData = appUtils.getAllFormData(FormData);
//     try {
//       const response = await apiInterceptor.post(process.env.REACT_APP_BASE_URL+`/api/auth/department/${id}/${lang}`, formData, appUtils.getAuthHeader());
//       toast.success(i18n.t("Successfully edited department details"));
//       formAction.resetForm();
//       setOpen(false);
//       return response.data;
//     } catch (err) {
//       return rejectWithValue(err.response.data);
//     }
//   }
// );
//Edit Employee Action
export const DepartmentEditAction = createAsyncThunk(
  "restaurant/DepartmentEditAction",
  async (data, { rejectWithValue }) => {
    const { FormData, navigate, id,lang } = data;
    const formData = appUtils.getAllFormData(FormData);
    try {
      const response = await apiInterceptor.post(process.env.REACT_APP_BASE_URL+`/api/auth/department/${id}/${lang}`, formData, appUtils.getAuthHeader());
      navigate(-1);
      toast.success(i18n.t("Successfully edited department details"));
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//Delete Department Action
export const DepartmentDeleteAction = createAsyncThunk(
  "restaurant/DepartmentDeleteAction",
  async (data, { rejectWithValue }) => {
    const { setOpen, id } = data;
    try {
      await apiInterceptor.delete(process.env.REACT_APP_BASE_URL+`/api/auth/department/${id}`, appUtils.getAuthHeader());
      setOpen();
      toast.success(i18n.t("Successfully deleted departmental data"));
      return { id: id };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// 従業員並び替え
export const OrderChangeEmployeeAction = createAsyncThunk("admin/OrderChangeEmployeeAction", async (data, { rejectWithValue }) => {
  const { FormData, setOpen, formAction } = data;
  const formData = appUtils.getFormData(FormData);
  try {
    const response = await apiInterceptor.post(process.env.REACT_APP_BASE_URL+"/api/auth/setEmployeeorder", FormData, appUtils.getAuthHeader());
    setOpen(false);
    formAction.resetForm();

    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// 部署並び替え
export const OrderChangeDepartmentAction = createAsyncThunk("admin/OrderChangeDepartmentAction", async (data, { rejectWithValue }) => {
  const { FormData, setOpen, formAction } = data;
  const formData = appUtils.getFormData(FormData);
  try {
    const response = await apiInterceptor.post(process.env.REACT_APP_BASE_URL+"/api/auth/setDepartmentOrder", FormData, appUtils.getAuthHeader());
    setOpen(false);
    formAction.resetForm();

    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});