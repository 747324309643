// material-ui
import { useMediaQuery, Container, Link, Typography, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import appUtils from "src/utils/appUtils";
// ==============================|| FOOTER - AUTHENTICATION ||============================== //

const AuthFooter = () => {
  const navigate = useNavigate();
  const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const SETURL = "/";
  const currentURL = window.location.pathname;
  const redirectLaw = () => {
    window.open('https://tsubomi.co.jp/law/', '_blank')
  };

  const redirectContact = () => {
    if(appUtils.isLogin()){
      var contacturl = "/client-support";
    }else{
      var contacturl = "/customer-support";
    }

    if (currentURL.includes('login')) {
      var contacturl = "/client-support";
    }
    navigate(contacturl);
  };

  // 現在のURLを取得
  if (currentURL.includes('login')) {
    localStorage.removeItem(SETURL);
    var redirectTo = '/';
  }

  return (
    <Container maxWidth="xl">
      <Stack
        direction={matchDownSM ? "column" : "row"}
        justifyContent={matchDownSM ? "center" : "space-between"}
        spacing={2}
        textAlign={matchDownSM ? "center" : "inherit"}
      >
        <Typography variant="subtitle2" color="secondary" component="span">
          &copy; {new Date().getFullYear()} 株式会社TSUBOMI &nbsp;
        </Typography>

        <Stack
          direction={matchDownSM ? "column" : "row"}
          spacing={matchDownSM ? 1 : 3}
          textAlign={matchDownSM ? "center" : "inherit"}
        >
          <Typography
            variant="subtitle2"
            color="secondary"
            onClick={redirectLaw}
            sx={{
              cursor: "pointer",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            {t("Specified Commercial Transactions Act")}
          </Typography>
          <Typography
            variant="subtitle2"
            color="secondary"
            onClick={() => {
              navigate("/privacy-policy");
            }}
            sx={{
              cursor: "pointer",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            {t("Privacy Policy")}
          </Typography>
          <Typography
            variant="subtitle2"
            color="secondary"
            onClick={() => {
              navigate("/terms-of-use");
            }}
            sx={{
              cursor: "pointer",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            {t("Terms of Use")}
          </Typography>
          <Typography
            variant="subtitle2"
            color="secondary"
            // onClick={() => {
            //   navigate("/client-support");
            // }}
            onClick={redirectContact}
            sx={{
              cursor: "pointer",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            {t("Contact Us")}
          </Typography>
        </Stack>
      </Stack>
    </Container>
  );
};

export default AuthFooter;
