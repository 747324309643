import { lazy } from "react";

// project import
import PrivateRoute from "./PrivateRoute";
import Loadable from "src/components/Loadable";
import MainLayout from "src/layout/MainLayout";

// lazy Loading
const EmployeesListPage = Loadable(lazy(() => import("src/pages/Employees/EmployeesListPage")));
const EmployeeDetail = Loadable(lazy(() => import("src/pages/Employees/EmployeeDetailPage")));
const EditEmployeeDetail = Loadable(lazy(() => import("src/pages/Employees/EmployeeEditPage")));
const RestaurantCommentPage = Loadable(lazy(() => import("src/pages/Comments/RestaurantCommentsPage")));
const Tips = Loadable(lazy(() => import("src/pages/Tips/Restaurant/Tips")));
const DepartmentListPage = Loadable(lazy(() => import("src/pages/Department/DepartmentListPage")));
const EditDepartmentDetail = Loadable(lazy(() => import("src/pages/Department/DepartmentEditPage")));

const AdminRoutes = {
  path: "/",
  element: (
    <PrivateRoute>
      <MainLayout />
    </PrivateRoute>
  ),
  children: [
    {
      path: "employees",
      element: <EmployeesListPage />,
    },
    {
      path: "employee/:id",
      element: <EmployeeDetail />,
    },
    {
      path: "employee/edit/:id",
      element: <EditEmployeeDetail />,
    },
    {
      path: "comments",
      element: <RestaurantCommentPage />,
    },
    {
      path: "tips",
      element: <Tips />,
    },
    {
      path: "department",
      element: <DepartmentListPage />,
    },
    {
      path: "department/edit/:id",
      element: <EditDepartmentDetail />,
    },
  ],
};

export default AdminRoutes;
