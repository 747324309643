// project import
import React, { useEffect, useRef } from "react";
import ThemeCustomization from "src/themes";
import ScrollTop from "./components/ScrollTop";
import ThemeRoutes from "./routes";
import { store } from "src/store";
// third-party
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "./i18n";
import GlobalStyles from "./themes/globalStyles";

import TagManager from 'react-gtm-module';
import { Helmet, HelmetProvider } from 'react-helmet-async'
import "./App.scss";

const App = () => {
  const didLogRef = useRef(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!didLogRef.current) {
        didLogRef.current = true;
        TagManager.initialize({ gtmId: process.env.REACT_APP_TAG });
      }
    }, 1000); // 1000ミリ秒（＝1秒）の遅延
    return () => clearTimeout(timer);
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CoCoRo | 株式会社TSUBOMI</title>
      </Helmet>
      <ReduxProvider store={store}>
        <BrowserRouter basename="/">
          <ThemeCustomization>
            <ScrollTop>
              <ThemeRoutes />
              <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={3000} />
            </ScrollTop>
            <GlobalStyles />
          </ThemeCustomization>
        </BrowserRouter>
      </ReduxProvider>
    </HelmetProvider>
  );
}


export default App;
