import { format, formatDistance, differenceInCalendarDays } from "date-fns";
import { enGB, ja } from "date-fns/locale";
import i18n from "src/i18n";

export const TOKEN_KEY = "tips_ui_web_app_token";

export const appUtils = {
  formatDate: (date) => {
    let deff_date = new Date();
    let result = differenceInCalendarDays(deff_date, new Date(date));
    
    if(result === 0){
      return format(new Date(date), '今日 HH:mm');
    }else if(result === 1){
      return format(new Date(date), '昨日 HH:mm');
    }else if(result === 2){
      return format(new Date(date), '2日前 HH:mm');
    }else if(result === 3){
      return format(new Date(date), '3日前 HH:mm');
    }else{
      return format(new Date(date), 'yyyy-MM-dd HH:mm');
    }
    // if(result > 3){
    //   return format(new Date(date), 'yyyy-MM-dd m:s');
    // }else{
    //   return formatDistance(new Date(date), new Date(), { addSuffix: true, locale: i18n.language === "jp" ? ja : enGB });
    // }
    
  },
  getYearMonth: (date) => {
    return format(new Date(date), "MMMM yyyy", { locale: i18n.language === "jp" ? ja : enGB });
  },
  getMonth: (date) => {
    return format(new Date(date), "MMMM", { locale: i18n.language === "jp" ? ja : enGB });
  },
  setToken: (token) => {
    localStorage.setItem(TOKEN_KEY, token);
  },

  removeToken: () => {
    localStorage.removeItem(TOKEN_KEY);
  },

  getAppToken: () => localStorage.getItem(TOKEN_KEY),

  isLogin: () => {
    if (localStorage.getItem(TOKEN_KEY)) {
      return true;
    }
    return false;
  },
  getAuthHeader: () => {
    return {
      headers: {
        Authorization: "Bearer " + appUtils.getAppToken(),
        "Accept-Language": i18n.language === "jp" ? "ja-jp" : "en-us",
      },
    };
  },
  getNonAuthHeader: () => {
    return {
      headers: {
        "Accept-Language": i18n.language === "jp" ? "ja-jp" : "en-us",
      },
    };
  },

  clearLocalStorage: () => localStorage.clear(),

  getFormData: (data) => {
    let formdata = new FormData();
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[`${key}`]) {
          if (Array.isArray(data[`${key}`])) {
            data[`${key}`].forEach((v) => {
              if (typeof v === "object") {
                Object.keys(v).forEach((tempKey) => {
                  if (v[`${tempKey}`]) {
                    formdata.append(`${key}[${tempKey}]`, v[`${tempKey}`]);
                  }
                });
              } else {
                formdata.append(`${key}[]`, v);
              }
            });
          } else {
            formdata.append(key, data[`${key}`]);
          }
        }
      });
    }
    return formdata;
  },
  getAllFormData: (data) => {
    let formdata = new FormData();
    if (data) {
      Object.keys(data).forEach((key) => {
        if (Array.isArray(data[`${key}`])) {
          data[`${key}`].forEach((v) => {
            if (typeof v === "object") {
              Object.keys(v).forEach((tempKey) => {
                if (v[`${tempKey}`]) {
                  formdata.append(`${key}[${tempKey}]`, v[`${tempKey}`]);
                }
              });
            } else {
              formdata.append(`${key}[]`, v);
            }
          });
        } else {
          formdata.append(key, data[`${key}`]);
        }
      });
    }
    return formdata;
  },
  getQueryParams: (newQuery) => {
    var queryTemp = "";
    if (newQuery) {
      Object.keys(newQuery).forEach((key) => {
        if (newQuery[key]) {
          if (Array.isArray(newQuery[key]) && newQuery[key].length > 0) {
            newQuery[key].forEach((value) => {
              queryTemp = queryTemp ? `${queryTemp}&${key}=${value}` : `?${key}=${value}`;
            });
          } else if (!Array.isArray(newQuery[key])) {
            queryTemp = queryTemp ? `${queryTemp}&${key}=${newQuery[key]}` : `?${key}=${newQuery[key]}`;
          }
        }
      });
    }
    return queryTemp;
  },

  createObjectUrl: (val) => {
    if (typeof val === "string") {
      return val;
    } else {
      const url = URL.createObjectURL(val);
      return url;
    }
  },
  handleExcelExport: (url, setExportLoading) => {
    const a = document.createElement("a");
    a.href = url;
    a.rel = "noopener noreferrer";
    document.body.appendChild(a);
    a.click();
    setExportLoading(false);
    document.body.removeChild(a);
  },
};

export default appUtils;
