import { createSlice } from "@reduxjs/toolkit";
import { GetRestaurantsDetailByCustomerAction, TipsPaymentAction, TipsPaymentSuccessAction } from "./action";

export const initialState = {
  restaurantDetail: {
    loading: false,
    data: null,
  },
  tipsPayment: {
    loading: false,
    error: null,
  },
  tipsPaymentSuccess: {
    loading: false,
    data: null,
    error: null,
  },
};

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    //tips-payment-error-reset
    ResetTipsPaymentError(state, action) {
      if (action?.payload?.name) {
        state.tipsPayment.error[action.payload.name] = null;
      } else {
        state.tipsPayment.error = null;
      }
    },
  },
  extraReducers: (builder) => {
    //restaurant detail by customer reducer
    builder.addCase(GetRestaurantsDetailByCustomerAction.pending, (state) => {
      state.restaurantDetail.loading = true;
    });
    builder.addCase(GetRestaurantsDetailByCustomerAction.fulfilled, (state, action) => {
      state.restaurantDetail.loading = false;
      state.restaurantDetail.data = action.payload.data;
    });
    builder.addCase(GetRestaurantsDetailByCustomerAction.rejected, (state) => {
      state.restaurantDetail.loading = false;
      state.restaurantDetail.data = {};
    });
    //tips payment reducer
    builder.addCase(TipsPaymentAction.pending, (state) => {
      state.tipsPayment.loading = true;
    });
    builder.addCase(TipsPaymentAction.fulfilled, (state) => {
      state.tipsPayment.error = null;
    });
    builder.addCase(TipsPaymentAction.rejected, (state, action) => {
      state.tipsPayment.loading = false;
      state.tipsPayment.error = action.payload?.errors;
    });
    //tips payment success reducer
    builder.addCase(TipsPaymentSuccessAction.pending, (state) => {
      state.tipsPaymentSuccess.loading = true;
    });
    builder.addCase(TipsPaymentSuccessAction.fulfilled, (state, action) => {
      state.tipsPaymentSuccess.loading = false;
      state.tipsPaymentSuccess.data = action.payload.data;
      state.tipsPaymentSuccess.error = null;
    });
    builder.addCase(TipsPaymentSuccessAction.rejected, (state, action) => {
      state.tipsPaymentSuccess.loading = false;
      state.tipsPaymentSuccess.error = action.payload?.errors;
    });
  },
});

export default customerSlice;
