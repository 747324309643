import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import logoImg from "src/assets/images/logo/logo.png";

// material-ui
import { ButtonBase } from "@mui/material";
import config from "src/config";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";


// project import

// ==============================|| MAIN LOGO ||============================== //
const LogoSection = ({ sx, to }) => {
  const { t } = useTranslation();
  // 現在のURLを取得
  const currentURL = window.location.pathname;

  // リダイレクト先のURLを生成
  const SETURL = "/";
  var redirectTo = '/';

  if (currentURL.includes('restaurant-detail')) {
    localStorage.setItem(SETURL, currentURL);
    var redirectTo = currentURL;
  }

  if (localStorage.getItem(SETURL)) {
    if (currentURL.includes('privacy-policy') || currentURL.includes('terms-of-use') || currentURL.includes('client-support') || currentURL.includes('customer-support') || currentURL.includes('restaurant-detail')) {
      var redirectTo = localStorage.getItem(SETURL);
    } else {
      localStorage.removeItem(SETURL);
      var redirectTo = '/';
    }
  } else {
    var redirectTo = '/';
  }

  const lang = localStorage.getItem("language") || "jp";

  return (
    <ButtonBase disableRipple component={Link} to={redirectTo} sx={sx}>
      <Box className="header-logo" style={{ display: "flex", alignItems: "center" }}>
        <img src={logoImg} alt="" style={{ height: "inherit" }} />
        {lang === "jp" && (
          <p><span class="header-first">{t('header_sub1')}</span><br /><span class="header-second">{t('header_sub2')}</span></p>
        )}
        {lang === "en" && (
          <p><span class="header-first-en">”CoCoRo” - A service to express gratitude</span><br /><span class="header-second-en">to staff and teams</span></p>
        )}
        {lang === "kr" && (
          <p><span class="header-first">{t('header_sub1')}</span><br /><span class="header-second">{t('header_sub2')}</span></p>
        )}
        {lang === "zh" && (
          <p><span class="header-first">{t('header_sub1')}</span><br /><span class="header-second">{t('header_sub2')}</span></p>
        )}
      </Box>
    </ButtonBase>
  );
};

LogoSection.propTypes = {
  sx: PropTypes.object,
  to: PropTypes.string,
};

export default LogoSection;
