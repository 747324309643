import { lazy } from "react";

// project import
import PrivateRoute from "./PrivateRoute";
import Loadable from "src/components/Loadable";
import MainLayout from "src/layout/MainLayout";

// lazy Loading
const RestaurantDetail = Loadable(lazy(() => import("src/pages/Restaurant/RestaurantDetailPage")));
const EditRestaurantDetail = Loadable(lazy(() => import("src/pages/Restaurant/EditRestaurantDetailPage")));
const RestaurantListPage = Loadable(lazy(() => import("src/pages/Restaurant/RestaurantListPage")));
const PermissionManagement = Loadable(lazy(() => import("src/pages/settings/PermisionManagement")));
const AdminCommentsPage = Loadable(lazy(() => import("src/pages/Comments/AdminCommentsPage")));
const Tips = Loadable(lazy(() => import("src/pages/Tips/Admin/Tips")));

const AdminRoutes = {
  path: "/",
  element: (
    <PrivateRoute>
      <MainLayout />
    </PrivateRoute>
  ),
  children: [
    {
      path: "restaurants",
      element: <RestaurantListPage />,
    },
    {
      path: "restaurant/:id",
      element: <RestaurantDetail />,
    },
    {
      path: "restaurant/edit/:id",
      element: <EditRestaurantDetail />,
    },
    {
      path: "permission-management",
      element: <PermissionManagement />,
    },
    {
      path: "admin/comments",
      element: <AdminCommentsPage />,
    },
    {
      path: "admin/tips",
      element: <Tips />,
    },
  ],
};

export default AdminRoutes;
