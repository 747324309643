import apiInterceptor from "src/utils/apiUtils/apiInterceptor";
import { createAsyncThunk } from "@reduxjs/toolkit";
import appUtils from "src/utils/appUtils";
import { toast } from "react-toastify";
import i18n from "src/i18n";
import { format } from "date-fns";

//Restaurants List Action
export const GetRestaurantsListAction = createAsyncThunk(
  "admin/GetRestaurantsListAction",
  async (data, { rejectWithValue }) => {
    const { page, num } = data;
    try {
      const response = await apiInterceptor.get(process.env.REACT_APP_BASE_URL+`/api/auth/restaurant?num=${num}&page=${page}`, appUtils.getAuthHeader());
      return { response: response.data, page: page };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//Restaurant detail Action
export const GetRestaurantDetailAction = createAsyncThunk(
  "admin/GetRestaurantDetailAction",
  async (data, { rejectWithValue }) => {
    const { id } = data;
    try {
      const response = await apiInterceptor.get(process.env.REACT_APP_BASE_URL+`/api/auth/restaurant/${id}`, appUtils.getAuthHeader());
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//Restaurant Add Action
export const RestaurantAddAction = createAsyncThunk("admin/RestaurantAddAction", async (data, { rejectWithValue }) => {
  const { FormData, setOpen, formAction } = data;
  const formData = appUtils.getFormData(FormData);
  try {
    const response = await apiInterceptor.post(process.env.REACT_APP_BASE_URL+"/api/auth/restaurant", formData, appUtils.getAuthHeader());
    setOpen(false);
    formAction.resetForm();
    toast.success(i18n.t("Restaurant added successfully"));
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

//Restaurant Edit Action
export const RestaurantEditAction = createAsyncThunk(
  "admin/RestaurantEditAction",
  async (data, { rejectWithValue }) => {
    const { FormData, navigate, id, lang } = data;
    const formData = appUtils.getAllFormData(FormData);
    try {
      const response = await apiInterceptor.post(process.env.REACT_APP_BASE_URL+`/api/auth/restaurant/${id}/${lang}`, formData, appUtils.getAuthHeader());
      navigate(-1);
      toast.success(i18n.t("Restaurant detail edited successfully"));
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//Restaurant Delete Action
export const RestaurantDeleteAction = createAsyncThunk(
  "admin/RestaurantDeleteAction",
  async (data, { rejectWithValue }) => {
    const { setOpen, id } = data;
    try {
      await apiInterceptor.delete(process.env.REACT_APP_BASE_URL+`/api/auth/restaurant/${id}`, appUtils.getAuthHeader());
      setOpen();
      toast.success(i18n.t("Restaurant data deleted successfully"));
      return { id: id };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//Comments List Action
export const GetCommentsListAction = createAsyncThunk(
  "admin/GetCommentsListAction",
  async (data, { rejectWithValue }) => {
    const { page, restaurant, filterValue, setFilterValue, setRestaurantId, selected, num } = data;
    try {
      if(selected !=""){
        var url = process.env.REACT_APP_BASE_URL+`/api/comment?num=${num}&selected=${selected}&page=${page}${restaurant ? "&restaurant=" + restaurant : ""}`;
      }else{
        var url = process.env.REACT_APP_BASE_URL+`/api/comment?num=${num}&page=${page}${restaurant ? "&restaurant=" + restaurant : ""}`;
      }

      const response = await apiInterceptor.get(
        url,
        appUtils.getAuthHeader()
      );

      setFilterValue(filterValue);
      setRestaurantId(restaurant);
      return { response: response.data, page: page };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//Comments filter List Action
export const GetCommentsFilterListAction = createAsyncThunk(
  "admin/GetCommentsFilterListAction",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiInterceptor.get(process.env.REACT_APP_BASE_URL+`/api/comment`, appUtils.getAuthHeader());
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//Restaurant search Action
export const RestaurantSearchAction = createAsyncThunk(
  "admin/RestaurantSearchAction",
  async (data, { rejectWithValue }) => {
    const { query } = data;
    try {
      const response = await apiInterceptor.get(process.env.REACT_APP_BASE_URL+`/api/auth/restaurant?search=${query}`, appUtils.getAuthHeader());
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//Admin Tips Get Action
export const GetTipsAction = createAsyncThunk("admin/GetTipsAction", async (data, { rejectWithValue }) => {
  const { date, setFilterResultDate } = data;
  const year = format(new Date(date), "yyyy");
  const month = format(new Date(date), "MM");
  try {
    const response = await apiInterceptor.get(
      process.env.REACT_APP_BASE_URL+`/api/tip-collected?year=${year}&month=${month}`,
      appUtils.getAuthHeader()
    );
    setFilterResultDate(date);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

//Email Resend Action
export const EmailResendAction = createAsyncThunk("admin/EmailResendAction", async (data, { rejectWithValue }) => {
  const { id } = data;
  try {
    const response = await apiInterceptor.get(process.env.REACT_APP_BASE_URL+`/api/auth/restaurant/${id}/resend`, appUtils.getAuthHeader());
    toast.success(i18n.t("email has been resend successfully"));
    return { response: response.data, id: id };
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const GetRestaurantAllListAction = createAsyncThunk("admin/GetRestaurantAllListAction",async (data, { rejectWithValue }) => {
    return 'hoge';
    // const { restaurant } = data;
    try {
      // const response = await apiInterceptor.get(process.env.REACT_APP_BASE_URL+`/api/auth/restaurantlist`, appUtils.getAuthHeader());
      // console.log(response.data)
      // return { response: response.data};
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const fetchData2 = createAsyncThunk("admin/fetchData2", async (data, { rejectWithValue }) => {
  try {
    const response = await apiInterceptor.get(process.env.REACT_APP_BASE_URL+`/api/auth/restaurantlist`, appUtils.getAuthHeader());
    console.log('11111')
    console.log(response.data)
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});