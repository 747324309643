// third-party
import { configureStore } from "@reduxjs/toolkit";

// project import
import reducers from "./reducers";

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

const store = configureStore({
	reducer: reducers,
	devTools: process.env.NODE_ENV === "development",
});

const { dispatch } = store;

export { store, dispatch };
