import { createSlice } from "@reduxjs/toolkit";
import {
  EmailResendAction,
  GetCommentsFilterListAction,
  GetCommentsListAction,
  GetRestaurantDetailAction,
  GetRestaurantsListAction,
  GetTipsAction,
  RestaurantAddAction,
  RestaurantDeleteAction,
  RestaurantEditAction,
  RestaurantSearchAction,
} from "./action";

export const initialState = {
  restaurantList: {
    loading: false,
    data: null,
    total: 0,
    limit: 0,
    currentPage: 1,
  },
  restaurantDetail: {
    loading: false,
    data: null,
  },
  restaurantAdd: {
    loading: false,
    error: null,
  },
  restaurantEdit: {
    loading: false,
    error: null,
  },
  restaurantDelete: {
    loading: false,
    error: null,
  },
  commentsList: {
    loading: false,
    data: null,
    total: 0,
    limit: 0,
    currentPage: 1,
  },
  commentsFilterList: {
    loading: false,
    data: null,
  },
  restaurantSearch: {
    loading: false,
    data: null,
  },
  tips: {
    loading: false,
    data: null,
  },
  emailResend: {
    loading: {},
  },
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    //restaurant-add-error-reset
    ResetRestaurantAddError(state, action) {
      if (action?.payload?.name) {
        state.restaurantAdd.error[action.payload.name] = null;
      } else {
        state.restaurantAdd.error = null;
      }
    },
    //restaurant-edit-error-reset
    ResetRestaurantEditError(state, action) {
      if (action?.payload?.name) {
        state.restaurantEdit.error[action.payload.name] = null;
      } else {
        state.restaurantEdit.error = null;
      }
    },
    //restaurant-search-clear
    ClearRestaurantSearch(state, action) {
      state.restaurantSearch.data = null;
      action.payload.setQuery("");
    },
  },
  extraReducers: (builder) => {
    //Restaurant add reducer
    builder.addCase(RestaurantAddAction.pending, (state) => {
      state.restaurantAdd.loading = true;
    });
    builder.addCase(RestaurantAddAction.fulfilled, (state, action) => {
      state.restaurantAdd.loading = false;
      state.restaurantList.data = [action.payload.data, ...(state.restaurantList.data ?? [])];
      state.restaurantAdd.error = null;
    });
    builder.addCase(RestaurantAddAction.rejected, (state, action) => {
      state.restaurantAdd.loading = false;
      state.restaurantAdd.error = action.payload.errors;
    });

    //Restaurant edit reducer
    builder.addCase(RestaurantEditAction.pending, (state) => {
      state.restaurantEdit.loading = true;
    });
    builder.addCase(RestaurantEditAction.fulfilled, (state, action) => {
      state.restaurantEdit.loading = false;
      const index = state.restaurantList?.data?.findIndex((val) => val.id === action.payload.data.id);
      if (index !== -1) {
        state.restaurantList?.data?.splice(index, 1, action.payload.data);
      }
      state.restaurantDetail.data = action.payload.data;
      state.restaurantEdit.error = null;
    });
    builder.addCase(RestaurantEditAction.rejected, (state, action) => {
      state.restaurantEdit.loading = false;
      state.restaurantEdit.error = action.payload.errors;
    });

    //Restaurant delete reducer
    builder.addCase(RestaurantDeleteAction.pending, (state) => {
      state.restaurantDelete.loading = true;
    });
    builder.addCase(RestaurantDeleteAction.fulfilled, (state, action) => {
      state.restaurantDelete.loading = false;
      const index = state.restaurantList?.data?.findIndex((val) => val.id === action.payload.id);
      if (index !== -1) {
        state.restaurantList?.data?.splice(index, 1);
      }
      state.restaurantDelete.error = null;
    });
    builder.addCase(RestaurantDeleteAction.rejected, (state, action) => {
      state.restaurantDelete.loading = false;
      state.restaurantDelete.error = action.payload.errors;
    });

    //Restaurant list reducer
    builder.addCase(GetRestaurantsListAction.pending, (state) => {
      state.restaurantList.loading = true;
    });
    builder.addCase(GetRestaurantsListAction.fulfilled, (state, action) => {
      state.restaurantList.loading = false;
      state.restaurantList.data = action.payload.response.data;
      state.restaurantList.total = action.payload.response.total;
      state.restaurantList.limit = action.payload.response.post_per_page;
      state.restaurantList.currentPage = action.payload.page;
    });
    builder.addCase(GetRestaurantsListAction.rejected, (state, action) => {
      state.restaurantList.loading = false;
      state.restaurantList.data = [];
    });
    //Restaurant detail reducer
    builder.addCase(GetRestaurantDetailAction.pending, (state) => {
      state.restaurantDetail.loading = true;
    });
    builder.addCase(GetRestaurantDetailAction.fulfilled, (state, action) => {
      state.restaurantDetail.loading = false;
      state.restaurantDetail.data = action.payload.data;
    });
    builder.addCase(GetRestaurantDetailAction.rejected, (state, action) => {
      state.restaurantDetail.loading = false;
      state.restaurantDetail.data = {};
    });
    //Comments list reducer
    builder.addCase(GetCommentsListAction.pending, (state) => {
      state.commentsList.loading = true;
    });
    builder.addCase(GetCommentsListAction.fulfilled, (state, action) => {
      state.commentsList.loading = false;
      state.commentsList.data = action.payload.response.data;
      state.commentsList.total = action.payload.response.total;
      state.commentsList.limit = action.payload.response.post_per_page;
      state.commentsList.currentPage = action.payload.page;
    });
    builder.addCase(GetCommentsListAction.rejected, (state) => {
      state.commentsList.loading = false;
      state.commentsList.data = [];
    });
    //Comments filter list reducer
    builder.addCase(GetCommentsFilterListAction.pending, (state) => {
      state.commentsFilterList.loading = true;
    });
    builder.addCase(GetCommentsFilterListAction.fulfilled, (state, action) => {
      state.commentsFilterList.loading = false;
      state.commentsFilterList.data = action.payload?.data
        ?.map((val) => {
          return {
            restaurant_name: val?.waiter?.restaurant[0]?.name ?? "",
            restaurant_id: val?.waiter?.restaurant[0]?.id ?? "",
          };
        })
        .filter((fVal, pos, self) => self.findIndex((val) => val.restaurant_id === fVal.restaurant_id) === pos);
    });
    builder.addCase(GetCommentsFilterListAction.rejected, (state) => {
      state.commentsFilterList.loading = false;
    });
    //Restaurant search reducer
    builder.addCase(RestaurantSearchAction.pending, (state) => {
      state.restaurantSearch.loading = true;
    });
    builder.addCase(RestaurantSearchAction.fulfilled, (state, action) => {
      state.restaurantSearch.loading = false;
      state.restaurantSearch.data = action.payload.data;
    });
    builder.addCase(RestaurantSearchAction.rejected, (state) => {
      state.restaurantSearch.loading = false;
    });
    //Admin Tips detail reducer
    builder.addCase(GetTipsAction.pending, (state) => {
      state.tips.loading = true;
    });
    builder.addCase(GetTipsAction.fulfilled, (state, action) => {
      state.tips.loading = false;
      state.tips.data = action.payload.data;
    });
    builder.addCase(GetTipsAction.rejected, (state) => {
      state.tips.loading = false;
    });
    //email resend reducer
    builder.addCase(EmailResendAction.pending, (state, action) => {
      state.emailResend.loading = {
        [`${action?.meta?.arg?.id}`]: true,
      };
    });
    builder.addCase(EmailResendAction.fulfilled, (state, action) => {
      state.emailResend.loading = {
        [`${action.payload.id}`]: false,
      };
    });
    builder.addCase(EmailResendAction.rejected, (state, action) => {
      state.emailResend.loading = {
        [`${action?.meta?.arg?.id}`]: true,
      };
    });
  },
});

export default adminSlice;
