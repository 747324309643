import PropTypes from "prop-types";
// material-ui
import { useTheme } from "@mui/material/styles";
import { List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

// assets
import { EditOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

const ProfileTab = ({ handleLogout, handleToggle, data }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <List component="nav" sx={{ p: 0, "& .MuiListItemIcon-root": { minWidth: 32, color: theme.palette.grey[500] } }}>
      <ListItemButton
        selected={pathname === "/profile"}
        onClick={() => {
          navigate("profile");
          handleToggle();
        }}
      >
        <ListItemIcon>
          <UserOutlined />
        </ListItemIcon>
        <ListItemText primary={t("View Profile")} />
      </ListItemButton>
      <ListItemButton
        selected={pathname === "/edit-profile"}
        onClick={() => {
          navigate("/edit-profile", {
            state: {
              data: data,
            },
          });
          handleToggle();
        }}
      >
        <ListItemIcon>
          <EditOutlined />
        </ListItemIcon>
        <ListItemText primary={t("Edit Profile")} />
      </ListItemButton>
      <ListItemButton onClick={handleLogout}>
        <ListItemIcon>
          <LogoutOutlined />
        </ListItemIcon>
        <ListItemText primary={t("logout")} />
      </ListItemButton>
    </List>
  );
};

ProfileTab.propTypes = {
  handleLogout: PropTypes.func,
};

export default ProfileTab;
