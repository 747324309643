// ==============================|| THEME CONFIG  ||============================== //

const config = {
	defaultPath: "/",
	fontFamily: `'Public Sans', sans-serif`,
	i18n: "en",
	miniDrawer: false,
	container: true,
	mode: "light",
	presetColor: "default",
	themeDirection: "ltr",
};

export default config;
