import { createSlice } from "@reduxjs/toolkit";
import {
  AddDepartmentAction,
  AddEmployeeAction,
  DepartmentDeleteAction,
  DepartmentEditAction,
  EmployeeDeleteAction,
  EmployeeEditAction,
  GetCommentsListAction,
  GetDepartmentListAction,
  GetEmployeesDetailAction,
  GetEmployeesListAction,
  GetTipsAction,
  SearchEmployeesAction,
} from "./action";

export const initialState = {
  employeesList: {
    loading: false,
    data: null,
    total: 0,
    limit: 0,
    currentPage: 1,
  },
  employeesDetail: {
    loading: false,
    data: null,
  },
  employeeAdd: {
    loading: false,
    error: null,
  },
  employeeEdit: {
    loading: false,
    error: null,
  },
  employeeDelete: {
    loading: false,
    error: null,
  },
  commentsList: {
    currentPage: 1,
    loading: false,
    data: null,
    total: 0,
    limit: 0,
  },
  employeesSearch: {
    loading: false,
    data: null,
  },
  tips: {
    loading: false,
    data: null,
  },
  departmentList: {
    loading: false,
    data: null,
  },
  departmentAdd: {
    loading: false,
    error: null,
  },
  departmentEdit: {
    loading: false,
    error: null,
  },
  departmentDelete: {
    loading: false,
    error: null,
  },
};

export const restaurantSlice = createSlice({
  name: "restaurant",
  initialState,
  reducers: {
    //employee-add-error-reset
    ResetEmployeeAddError(state, action) {
      if (action?.payload?.name) {
        state.employeeAdd.error[action.payload.name] = null;
      } else {
        state.employeeAdd.error = null;
      }
    },
    //employee-edit-error-reset
    ResetEmployeeEditError(state, action) {
      if (action?.payload?.name) {
        state.employeeEdit.error[action.payload.name] = null;
      } else {
        state.employeeEdit.error = null;
      }
    },
    //employees-search-clear
    ClearEmployeeSearch(state, action) {
      state.employeesSearch.data = null;
      action.payload.setQuery("");
    },
    //department-add-error-reset
    ResetDepartmentAddError(state, action) {
      if (action?.payload?.name) {
        state.departmentAdd.error[action.payload.name] = null;
      } else {
        state.departmentAdd.error = null;
      }
    },
    //department-edit-error-reset
    ResetDepartmentEditError(state, action) {
      if (action?.payload?.name) {
        state.departmentEdit.error[action.payload.name] = null;
      } else {
        state.departmentEdit.error = null;
      }
    },
  },
  extraReducers: (builder) => {
    //employees list reducer
    builder.addCase(GetEmployeesListAction.pending, (state) => {
      state.employeesList.loading = true;
    });
    builder.addCase(GetEmployeesListAction.fulfilled, (state, action) => {
      state.employeesList.loading = false;
      state.employeesList.data = action.payload.response.data;
      state.employeesList.total = action.payload.response.total;
      state.employeesList.limit = action.payload.response.post_per_page;
      state.employeesList.currentPage = action.payload.page;
    });
    builder.addCase(GetEmployeesListAction.rejected, (state) => {
      state.employeesList.loading = false;
      state.employeesList.data = [];
    });
    //employees detail reducer
    builder.addCase(GetEmployeesDetailAction.pending, (state) => {
      state.employeesDetail.loading = true;
    });
    builder.addCase(GetEmployeesDetailAction.fulfilled, (state, action) => {
      state.employeesDetail.loading = false;
      state.employeesDetail.data = action.payload.data;
    });
    builder.addCase(GetEmployeesDetailAction.rejected, (state) => {
      state.employeesDetail.loading = false;
      state.employeesDetail.data = {};
    });
    //employee add reducer
    builder.addCase(AddEmployeeAction.pending, (state) => {
      state.employeeAdd.loading = true;
    });
    builder.addCase(AddEmployeeAction.fulfilled, (state, action) => {
      state.employeeAdd.loading = false;
      state.employeesList.data = [action.payload.data, ...(state.employeesList.data ?? [])];
      state.employeeAdd.error = null;
    });
    builder.addCase(AddEmployeeAction.rejected, (state, action) => {
      state.employeeAdd.loading = false;
      state.employeeAdd.error = action.payload.errors;
    });
    //employee edit reducer
    builder.addCase(EmployeeEditAction.pending, (state) => {
      state.employeeEdit.loading = true;
    });
    builder.addCase(EmployeeEditAction.fulfilled, (state, action) => {
      const index = state.employeesList?.data?.findIndex((val) => val.id === action.payload.data.id);
      if (index !== -1) {
        state.employeesList?.data?.splice(index, 1, action.payload.data);
      }
      state.employeesDetail.data = action.payload.data;
      state.employeeEdit.loading = false;
      state.employeeEdit.error = null;
    });
    builder.addCase(EmployeeEditAction.rejected, (state, action) => {
      state.employeeEdit.loading = false;
      state.employeeEdit.error = action.payload.errors;
    });
    //Employee delete reducer
    builder.addCase(EmployeeDeleteAction.pending, (state) => {
      state.employeeDelete.loading = true;
    });
    builder.addCase(EmployeeDeleteAction.fulfilled, (state, action) => {
      state.employeeDelete.loading = false;
      const index = state.employeesList?.data?.findIndex((val) => val.id === action.payload.id);
      if (index !== -1) {
        state.employeesList?.data?.splice(index, 1);
      }
      state.employeeDelete.error = null;
    });
    builder.addCase(EmployeeDeleteAction.rejected, (state, action) => {
      state.employeeDelete.loading = false;
      state.employeeDelete.error = action.payload.errors;
    });
    //Comments list reducer
    builder.addCase(GetCommentsListAction.pending, (state) => {
      state.commentsList.loading = true;
    });
    builder.addCase(GetCommentsListAction.fulfilled, (state, action) => {
      state.commentsList.loading = false;
      state.commentsList.data = action.payload.response.data;
      state.commentsList.total = action.payload.response.total;
      state.commentsList.limit = action.payload.response.post_per_page;
      state.commentsList.currentPage = action.payload.page;
    });
    builder.addCase(GetCommentsListAction.rejected, (state) => {
      state.commentsList.loading = false;
      state.commentsList.data = [];
    });
    //employees search reducer
    builder.addCase(SearchEmployeesAction.pending, (state) => {
      state.employeesSearch.loading = true;
    });
    builder.addCase(SearchEmployeesAction.fulfilled, (state, action) => {
      state.employeesSearch.loading = false;
      state.employeesSearch.data = action.payload.data;
    });
    builder.addCase(SearchEmployeesAction.rejected, (state) => {
      state.employeesSearch.loading = false;
    });
    //Restaurant Tips detail reducer
    builder.addCase(GetTipsAction.pending, (state) => {
      state.tips.loading = true;
    });
    builder.addCase(GetTipsAction.fulfilled, (state, action) => {
      state.tips.loading = false;
      state.tips.data = action.payload.data;
    });
    builder.addCase(GetTipsAction.rejected, (state) => {
      state.tips.loading = false;
    });
    //department list reducer
    builder.addCase(GetDepartmentListAction.pending, (state) => {
      state.departmentList.loading = true;
    });
    builder.addCase(GetDepartmentListAction.fulfilled, (state, action) => {
      state.departmentList.loading = false;
      state.departmentList.data = action.payload.response.data;
    });
    builder.addCase(GetDepartmentListAction.rejected, (state) => {
      state.departmentList.loading = false;
      state.departmentList.data = [];
    });
    //Department add reducer
    builder.addCase(AddDepartmentAction.pending, (state) => {
      state.departmentAdd.loading = true;
    });
    builder.addCase(AddDepartmentAction.fulfilled, (state, action) => {
      state.departmentAdd.loading = false;
      state.departmentList.data = [action.payload.data, ...(state.departmentList.data ?? [])];
      state.departmentAdd.error = null;
    });
    builder.addCase(AddDepartmentAction.rejected, (state, action) => {
      state.departmentAdd.loading = false;
      state.departmentAdd.error = action.payload.errors;
    });
    //Department edit reducer
    builder.addCase(DepartmentEditAction.pending, (state) => {
      state.departmentEdit.loading = true;
    });
    builder.addCase(DepartmentEditAction.fulfilled, (state, action) => {
      const index = state.departmentList?.data?.findIndex((val) => val.id === action.payload.data.id);
      if (index !== -1) {
        state.departmentList?.data?.splice(index, 1, action.payload.data);
      }
      state.departmentEdit.loading = false;
      state.departmentEdit.error = null;
    });
    builder.addCase(DepartmentEditAction.rejected, (state, action) => {
      state.departmentEdit.loading = false;
      state.departmentEdit.error = action.payload.errors;
    });
    //Department delete reducer
    builder.addCase(DepartmentDeleteAction.pending, (state) => {
      state.departmentDelete.loading = true;
    });
    builder.addCase(DepartmentDeleteAction.fulfilled, (state, action) => {
      state.departmentDelete.loading = false;
      const index = state.departmentList?.data?.findIndex((val) => val.id === action.payload.id);
      if (index !== -1) {
        state.departmentList?.data?.splice(index, 1);
      }
      state.departmentDelete.error = null;
    });
    builder.addCase(DepartmentDeleteAction.rejected, (state, action) => {
      state.departmentDelete.loading = false;
      state.departmentDelete.error = action.payload.errors;
    });
  },
});

export default restaurantSlice;
