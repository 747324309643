import { lazy } from "react";
import Loadable from "src/components/Loadable";
import MinimalLayout from "src/layout/MinimalLayout";
// project imports
const PageNotFound = Loadable(lazy(() => import("src/pages/errorHandle/NotFound404")));
const ServerError = Loadable(lazy(() => import("src/pages/errorHandle/ServerError505")));

const ErrorHandlingRoutes = {
  path: "/",
  element: <MinimalLayout />,
  children: [
    {
      path: "/404",
      element: <PageNotFound />,
    },
    {
      path: "/500",
      element: <ServerError />,
    },
  ],
};

export default ErrorHandlingRoutes;
