import apiInterceptor from "src/utils/apiUtils/apiInterceptor";
import { createAsyncThunk } from "@reduxjs/toolkit";
import appUtils from "src/utils/appUtils";

//Restaurants Detail By Customer Action
export const GetRestaurantsDetailByCustomerAction = createAsyncThunk(
  "customer/GetRestaurantsDetailByCustomerAction",
  async (data, { rejectWithValue }) => {
    const { id } = data;
    try {
      const response = await apiInterceptor.get(process.env.REACT_APP_BASE_URL+`/api/restaurant/${id}`, appUtils.getNonAuthHeader());
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//Tips payment Action
export const TipsPaymentAction = createAsyncThunk("customer/TipsPaymentAction", async (data, { rejectWithValue }) => {
  data.payment = data.FormData.payment;
  const { FormData } = data;
  const formData = appUtils.getFormData(FormData);
    
  try {
    if(data.FormData.payment === 'paypay'){
      const response = await apiInterceptor.post(process.env.REACT_APP_BASE_URL+`/api/pay-tip`, formData, appUtils.getNonAuthHeader());
      window.location.href = response.data.url;
      return response.data;
    }else if(data.FormData.payment === 'card' || data.FormData.payment === 'google'){
      const response = await apiInterceptor.post(process.env.REACT_APP_BASE_URL+`/api/pay-tip-card`, formData, appUtils.getNonAuthHeader());
      if(response.data.ErrCode){
        const err = response.data.ErrCode;
        switch (err) {
          case 'G02':
            alert('残高が不足しています')
            // window.location.reload();
          case 'G03':
            alert('限度額を超えています')
            // window.location.reload();
          case 'G04':
            alert('残高が不足しています')
            // window.location.reload();
          case 'G05':
            alert('限度額を超えています')
            // window.location.reload();
          case 'G12':
            alert('取扱不可のカードです')
            // window.location.reload();
          case 'G22':
            alert('取扱不可のカードです')
            // window.location.reload();
          case 'G42':
            alert('暗証番号エラーです')
            // window.location.reload();
          case 'G44':
            alert('セキュリティコード誤りがあります')
            // window.location.reload();
          case 'G45':
            alert('セキュリティコード誤りがあります')
            // window.location.reload();
          case 'G54':
            alert('セキュリティコード誤りがあります')
            // window.location.reload();
          case 'G55':
            alert('限度額を超えています')
            // window.location.reload();
          case 'G60':
            alert('このカードは使用できません')
            // window.location.reload();
          case 'G61':
            alert('このカードは使用できません')
            // window.location.reload();
          default:
            alert('このカードは使用できません')
            // window.location.reload();
        }
      }else{
        window.location.href = response.data.url;
      }
    }else if(data.FormData.payment === 'apple'){
      const response = await apiInterceptor.post(process.env.REACT_APP_BASE_URL+`/api/pay-tip-card`, formData, appUtils.getNonAuthHeader());
      if(response.data.ErrCode){
        const err = response.data.ErrCode;
        console.log(err)
      }else{
        window.location.href = response.data.url;
      }

    }else if(data.FormData.payment === 'otameshi'){
      const response = await apiInterceptor.post(process.env.REACT_APP_BASE_URL+`/api/pay-tip-campaign`, formData, appUtils.getNonAuthHeader());
      if(response.data.ErrCode){
        const err = response.data.ErrCode;
        alert('送信を失敗しました。')
      }else{
        window.location.href = response.data.url;
      }
    }else{
      alert('送信エラー');
    }
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const ApplePayAction = createAsyncThunk("customer/ApplePayAction", async (data, { rejectWithValue }) => {
  console.log('action')
  console.log(data)
  const { FormData } = data;
  const formData = appUtils.getFormData(FormData);
  console.log(FormData)
  try {
    const response = await apiInterceptor.post(process.env.REACT_APP_BASE_URL+`/api/applepaytoken`, formData, appUtils.getNonAuthHeader());
    console.log('hoge2')
    console.log(response.data)
    return response.data;
  } catch (err) {
    // return rejectWithValue(err.response.data);
  }
});

//Tips payment success Action
export const TipsPaymentSuccessAction = createAsyncThunk(
  "customer/TipsPaymentSuccessAction",
  async (data, { rejectWithValue }) => {
    // 引数取得
    let url = new URL(window.location.href);
    let params = url.searchParams;
    var payment = params.get('payment'); 
    var payment_id = params.get('payment_id');
    const { id } = data;
    try {
      const response = await apiInterceptor.get(process.env.REACT_APP_BASE_URL+`/api/payment-status/${id}?payment=${payment}&payment_id=${payment_id}`, appUtils.getNonAuthHeader());
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// 部屋番号取得
export const GetRoomNumberByCustomerAction = createAsyncThunk(
  "customer/GetRoomNumberByCustomerAction",
  async (data, { rejectWithValue }) => {
    const { id } = data;
    try {
      const response = await apiInterceptor.get(process.env.REACT_APP_BASE_URL+`/api/get-roomnumber/${id}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);