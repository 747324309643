import { toast } from "react-toastify";
import appUtils from "src/utils/appUtils";
import apiInterceptor from "src/utils/apiUtils/apiInterceptor";
import { createAsyncThunk } from "@reduxjs/toolkit";
import i18n from "src/i18n";

//Register Action
export const RegisterAction = createAsyncThunk("auth/RegisterAction", async (data, { rejectWithValue }) => {
  const { navigate, pathname, FormData } = data;
  try {
    const formData = appUtils.getFormData(FormData);
    const response = await apiInterceptor.post(process.env.REACT_APP_BASE_URL+`/api${pathname}`, formData, appUtils.getAuthHeader());
    toast.success(i18n.t("Verified successfully. Please login with your email"));
    navigate("/login");
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

//Login Action
export const LoginAction = createAsyncThunk("auth/LoginAction", async (data, { rejectWithValue }) => {
  const { FormData } = data;
  try {
    const formData = appUtils.getFormData(FormData);
    const response = await apiInterceptor.post(process.env.REACT_APP_BASE_URL+"/api/auth/login", formData, appUtils.getAuthHeader());
    appUtils.setToken(response.data?.data.token ?? "");
    window.location.href = "/";
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

//Forgot Password Action
export const ForgotPasswordAction = createAsyncThunk("auth/ForgotPasswordAction", async (data, { rejectWithValue }) => {
  const { FormData } = data;
  try {
    const formData = appUtils.getFormData(FormData);
    const response = await apiInterceptor.post(`/password/email`, formData, appUtils.getAuthHeader());
    toast.success(i18n.t("Password reset link has been sent to your email.Please check your email"));
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

//Reset Password Action
export const ResetPasswordAction = createAsyncThunk("auth/ResetPasswordAction", async (data, { rejectWithValue }) => {
  const { FormData, navigate, pathname } = data;
  try {
    const formData = appUtils.getFormData(FormData);
    const response = await apiInterceptor.post(`${pathname}`, formData, appUtils.getAuthHeader());
    toast.success(i18n.t("Password reset successful.Login with a new password"));
    navigate("/login");
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});
