import { lazy } from "react";

// project import
import Loadable from "src/components/Loadable";
import MinimalLayout from "src/layout/MinimalLayout";
import ContactUs from "src/pages/agreement/ContactUs";
import CustomerSupport from "src/pages/agreement/CustomerSupport";
import ClientSupport from "src/pages/agreement/ClientSupport";

// lazy Loading
const PrivacyPolicy = Loadable(lazy(() => import("src/pages/agreement/PrivacyPolicy")));
const TermsOfUse = Loadable(lazy(() => import("src/pages/agreement/TermsOfUse")));

const AgreementRoutes = {
  path: "/",
  element: <MinimalLayout />,
  children: [
    {
      path: "privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "terms-of-use",
      element: <TermsOfUse />,
    },
    {
      path: "contact-us",
      element: <ContactUs />,
    },
    {
      path: "client-support",
      element: <ClientSupport />,
    },
    {
      path: "customer-support",
      element: <CustomerSupport />,
    },
  ],
};

export default AgreementRoutes;
