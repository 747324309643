// material
import { GlobalStyles as GlobalThemeStyles } from "@mui/material";
import backgroundImage from "src/assets/images/background/tips-background.png"
import React from "react";

// ----------------------------------------------------------------------

export default function GlobalStyles() {
  return (
    <GlobalThemeStyles
      styles={{
        "*": {
          margin: 0,
          padding: 0,
          boxSizing: "border-box",
          scrollBehavior: "smooth",
          scrollbarWidth: "thin",
          scrollbarColor: "rgba(117, 117, 117, 0.719)",
        },
        "::-webkit-scrollbar": {
          width: "5px",
          height: "5px",
          backgroundColor: "transparent",
          "&:hover": {
            backgroundColor: "rgba(221, 221, 221, 0.918)",
          },
        },
        "::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(117, 117, 117, 0.719)",
          borderRadius: "4px",
        },
        html: {
          width: "100%",
          height: "100%",
          WebkitOverflowScrolling: "touch",
        },
        body: {
          width: "100%",
          height: "100%",
        },
        "#root": {
          width: "100%",
          height: "100%",
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat:"repeat",
          backgroundSize: "auto 100%",
          backgroundPosition: "center",
          overflowY:"auto"
        },
      }}
    />
  );
}
