import apiInterceptor from "src/utils/apiUtils/apiInterceptor";
import { createAsyncThunk } from "@reduxjs/toolkit";
import appUtils from "src/utils/appUtils";
import { toast } from "react-toastify";
import i18n from "src/i18n";

//Roles List Get Action
export const GetRolesListAction = createAsyncThunk("setting/GetRolesListAction", async (_, { rejectWithValue }) => {
  try {
    const response = await apiInterceptor.get(process.env.REACT_APP_BASE_URL+"/api/auth/role", appUtils.getAuthHeader());
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

//Permissions List Get Action
export const GetPermissionsListAction = createAsyncThunk(
  "setting/GetPermissionsListAction",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiInterceptor.get(process.env.REACT_APP_BASE_URL+"/api/auth/permission", appUtils.getAuthHeader());
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//Permissions Update Action
export const UpdatePermissionsAction = createAsyncThunk(
  "setting/UpdatePermissionsAction",
  async (data, { rejectWithValue }) => {
    const { FormData, setOpen } = data;
    try {
      const response = await apiInterceptor.post(process.env.REACT_APP_BASE_URL+"/api/auth/assign-permission", FormData, appUtils.getAuthHeader());
      toast.success(i18n.t("Permission Updated Successfully"));
      setOpen(false);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
