import { Outlet } from "react-router-dom";

// ==============================|| AUTH LAYOUT ||============================== //

const AuthLayout = () => (
  <>
    <Outlet />
  </>
);

export default AuthLayout;
