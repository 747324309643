import { Outlet } from "react-router-dom";
// material-ui
import { Box, Toolbar, Container } from "@mui/material";

// project import
import Header from "./Header";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetProfileAction } from "src/store/Profile/action";

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.profile.profileDetail);
  useEffect(() => {
    if (!data) {
      dispatch(GetProfileAction());
    }
  }, [data, dispatch]);
  return (
    <Container maxWidth="lg" sx={{ display: "flex", flexDirection: "column", width: "100%", minHeight: "100vh" }}>
      <Header />
      <Toolbar />
      <Box component="main" sx={{ width: "100%", flexGrow: 1, p: { xs: 2, sm: 3 } }}>
        <Outlet />
      </Box>
    </Container>
  );
};

export default MainLayout;
