import { useRoutes } from "react-router-dom";

// project import
import LoginRoutes from "./LoginRoutes";
import MainRoutes from "./MainRoutes";
import ErrorHandlingRoutes from "./ErrorHandlingRoutes";
import AdminRoutes from "./AdminRoutes";
import RestaurantRoutes from "./RestaurantRoutes";
import CustomerRoutes from "./CustomerRoutes";
import AgreementRoutes from "./AgreementRoutes";
// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    MainRoutes,
    LoginRoutes,
    ErrorHandlingRoutes,
    AdminRoutes,
    RestaurantRoutes,
    CustomerRoutes,
    AgreementRoutes,
  ]);
}
