import { createSlice } from "@reduxjs/toolkit";
import { ChangePasswordAction, EditProfileAction, GenerateQrCodeAction, GetProfileAction } from "./action";

export const initialState = {
  profileDetail: {
    loading: false,
    data: null,
  },
  permissions: [],
  profileEdit: {
    loading: false,
    error: null,
  },
  changePassword: {
    loading: false,
    error: null,
  },
  genearteQrCode: {
    loading: false,
    error: null,
  },
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    //Profile-edit-error-reset
    ResetProfileEditError(state, action) {
      if (action?.payload?.name) {
        state.profileEdit.error[action.payload.name] = null;
      } else {
        state.profileEdit.error = null;
      }
    },
    //change-password-error-reset
    ResetChangePswError(state, action) {
      if (action?.payload?.name) {
        state.changePassword.error[action.payload.name] = null;
      } else {
        state.changePassword.error = null;
      }
    },
  },
  extraReducers: (builder) => {
    //Profile Detail reducer
    builder.addCase(GetProfileAction.pending, (state) => {
      state.profileDetail.loading = true;
    });
    builder.addCase(GetProfileAction.fulfilled, (state, action) => {
      state.profileDetail.loading = false;
      state.profileDetail.data = action.payload.data;
      state.permissions = action.payload.data.permission ?? [];
    });
    builder.addCase(GetProfileAction.rejected, (state) => {
      state.profileDetail.loading = false;
      state.profileDetail.data = {};
    });
    //Profile Edit reducer
    builder.addCase(EditProfileAction.pending, (state) => {
      state.profileEdit.loading = true;
    });
    builder.addCase(EditProfileAction.fulfilled, (state, action) => {
      state.profileEdit.loading = false;
      state.profileDetail.data = {
        ...(state.profileDetail?.data ?? []),
        ...action.payload.data,
      };
      state.profileEdit.error = null;
    });
    builder.addCase(EditProfileAction.rejected, (state, action) => {
      state.profileEdit.loading = false;
      state.profileEdit.error = action.payload.errors;
    });
    //Change Password reducer
    builder.addCase(ChangePasswordAction.pending, (state) => {
      state.changePassword.loading = true;
    });
    builder.addCase(ChangePasswordAction.fulfilled, (state, action) => {
      state.changePassword.loading = false;
      state.changePassword.error = null;
    });
    builder.addCase(ChangePasswordAction.rejected, (state, action) => {
      state.changePassword.loading = false;
      state.changePassword.error = action.payload.errors;
    });
    //Qr code generate reducer
    builder.addCase(GenerateQrCodeAction.pending, (state) => {
      state.genearteQrCode.loading = true;
    });
    builder.addCase(GenerateQrCodeAction.fulfilled, (state, action) => {
      state.genearteQrCode.loading = false;
      state.genearteQrCode.data = action.payload.data;
      state.profileDetail.data = { ...(state.profileDetail.data ?? []), qr_code: action.payload.data };
    });
    builder.addCase(GenerateQrCodeAction.rejected, (state) => {
      state.genearteQrCode.loading = false;
    });
  },
});

export default profileSlice;
