import axios from "axios";
import { toast } from "react-toastify";
import appUtils from "../appUtils";

let failedQueue = [];

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const expectedError = error.response && error.response.status >= 400 && error.response.status < 500;
    if (expectedError && failedQueue.length === 0) {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        if (error?.response?.data?.status === "expired") {
          localStorage.clear();
          appUtils.setToken(error.response.data.token);
          window.location.reload();
        } else if (error.response.data["message"]) {
          toast.error("!!! Unauthorized logging out.");
          failedQueue.push(expectedError);
          originalRequest._retry = true;
          setTimeout(() => {
            localStorage.clear();
            window.location.href = "/login";
          }, 1000);
        } else {
          toast.error(error.response.data.statusText);
          failedQueue.push(expectedError);
          setTimeout(() => {
            localStorage.clear();
            window.location.href = "/login";
          }, 1000);
        }
      } else {
        if (error.response.status === 404 && failedQueue.length === 0) {
          window.location.href = "/404";
          failedQueue.push(expectedError);
        }
        if (error.response.data.detail) toast.error(error.response.data.detail);
        if (error.response.data.errors) {
          if (typeof error.response.data.errors === "object") {
            Object.keys(error.response.data.errors).forEach((key) => {
              toast.error(...error.response.data.errors[key]);
            });
          } else {
            toast.error(error.response.data.errors);
          }
        }
        if (error.response.data.message) {
          toast.error(error.response.data.message);
        }
      }
    }
    if (error.response.status === 500 && failedQueue.length === 0) {
      if (error.response.data.detail) toast.error(error.response.data.detail);
      else toast.error("Internal Server Error");
      failedQueue.push(expectedError);
      window.location.href = "/500";
    }
    return Promise.reject(error);
  }
);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
};
