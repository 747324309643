import { FormControl, MenuItem, Select, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const LanguageTranslation = () => {
  const { i18n } = useTranslation();
  const language = localStorage.getItem("language");
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [i18n, language]);

  return (
    <Stack>
      <FormControl size="small">
        <Select
          size="small"
          variant="outlined"
          defaultValue={language ?? "jp"}
          style={{ borderRadius: "6px", backgroundColor:"#fff" }}
          onChange={(e) => {
            i18n.changeLanguage(e.target.value);
            localStorage.setItem("language", e.target.value);
            if (e.target.value === "en") {
              document.title = "CoCoRo | 株式会社TSUBOMI";
            }
            if (e.target.value === "jp") {
              document.title = "CoCoRo | 株式会社TSUBOMI";
            }
            if (e.target.value === "kr") {
              document.title = "CoCoRo | 株式会社TSUBOMI";
            }
            if (e.target.value === "zh") {
              document.title = "CoCoRo | 株式会社TSUBOMI";
            }
          }}
        >
          <MenuItem value={"jp"}>JA</MenuItem>
          <MenuItem value={"en"}>EN</MenuItem>
          <MenuItem value={"zh"}>ZH</MenuItem>
          <MenuItem value={"kr"}>KO</MenuItem>
        </Select>
      </FormControl>
    </Stack>
  );
};

export default LanguageTranslation;
