import { lazy } from "react";

// project import
import Loadable from "src/components/Loadable";
import AuthLayout from "src/layout/AuthLayout";

//lazy imports
const Login = Loadable(lazy(() => import("src/pages/authentication/Login")));
const Register = Loadable(lazy(() => import("src/pages/authentication/Register")));
const ForgotPassword = Loadable(lazy(() => import("src/pages/authentication/ForgotPassword")));
const ResetPassword = Loadable(lazy(() => import("src/pages/authentication/ResetPassword")));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: "/",
  element: <AuthLayout />,
  children: [
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "email/verify/:id",
      element: <Register />,
    },
    {
      path: "password/forgot",
      element: <ForgotPassword />,
    },
    {
      path: "password/reset",
      element: <ResetPassword />,
    },
  ],
};

export default LoginRoutes;
