import apiInterceptor from "src/utils/apiUtils/apiInterceptor";
import { createAsyncThunk } from "@reduxjs/toolkit";
import appUtils from "src/utils/appUtils";
import { toast } from "react-toastify";
import i18n from "src/i18n";

//Profile Get Action
export const GetProfileAction = createAsyncThunk("profile/GetProfileAction", async (_, { rejectWithValue }) => {
  try {
    const response = await apiInterceptor.get(process.env.REACT_APP_BASE_URL+"/api/auth/profile", appUtils.getAuthHeader());
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

//Profile Edit Action
export const EditProfileAction = createAsyncThunk("profile/EditProfileAction", async (data, { rejectWithValue }) => {
  const { values, Redirect, lang } = data;
  const formData = appUtils.getFormData(values);
  try {
    const response = await apiInterceptor.post(process.env.REACT_APP_BASE_URL+`/api/auth/profile/edit/${lang}`, formData, appUtils.getAuthHeader());
    toast.success(i18n.t("Profile edited successfully"));
    Redirect("/profile");
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const GetTranslateHotelAction = createAsyncThunk(
  "restaurant/GetTranslateHotelAction",
  async (data, { rejectWithValue }) => {
    // const { page } = data;
    try {
      const response = await apiInterceptor.get(process.env.REACT_APP_BASE_URL+`/api/auth/translate_hotel/${data}`, appUtils.getAuthHeader());
      return { response: response.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//Change Password Action
export const ChangePasswordAction = createAsyncThunk(
  "profile/ChangePasswordAction",
  async (data, { rejectWithValue }) => {
    const { FormData, navigate } = data;
    const formData = appUtils.getFormData(FormData);
    try {
      const response = await apiInterceptor.post(process.env.REACT_APP_BASE_URL+"/api/auth/change-password", formData, appUtils.getAuthHeader());
      toast.success(i18n.t("Password changed successfully.Login with a new password"));
      navigate("/login");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
//Master Setting Action
export const MasterSettingAction = createAsyncThunk(
  "profile/MasterSettingAction",
  async (data, { rejectWithValue }) => {
    const { FormData, navigate } = data;
    console.log(data)
    const formData = appUtils.getFormData(FormData);
    try {
      const response = await apiInterceptor.post(process.env.REACT_APP_BASE_URL+"/api/auth/master-setting", formData, appUtils.getAuthHeader());
      toast.success(i18n.t("Master Setting changed successfully"));
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
//Qr-code generate action Action
export const GenerateQrCodeAction = createAsyncThunk(
  "profile/GenerateQrCodeaction",
  async (data, { rejectWithValue }) => {
    const { id } = data;
    try {
      const response = await apiInterceptor.get(process.env.REACT_APP_BASE_URL+`/api/auth/restaurant/${id}/qr-code`, appUtils.getAuthHeader());
      toast.success(i18n.t("Qr code generated successfully"));
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
