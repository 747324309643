// ==============================|| OVERRIDES - TYPOGRAPHY ||============================== //

export default function Typography() {
  return {
    MuiTypography: {
      styleOverrides: {
        root: {
          whiteSpace: "break-spaces",
          wordBreak: "break-word",
        },
        gutterBottom: {
          marginBottom: 12,
        },
      },
    },
  };
}
