import { lazy } from "react";

// project import
import Loadable from "src/components/Loadable";
import MinimalLayout from "src/layout/MinimalLayout";

// lazy Loading
const CustomerDashboard = Loadable(lazy(() => import("src/pages/Customer/dashboard/CustomerDashboard")));
const PaymentSuccess = Loadable(lazy(() => import("src/pages/Customer/components/PaymentSuccess")));
const CustomerDashboardTest = Loadable(lazy(() => import("src/pages/Customer/dashboard/CustomerDashboardTest")));

const CustomerRoutes = {
  path: "/",
  element: <MinimalLayout />,
  children: [
    {
      path: "restaurant-detail/:id",
      element: <CustomerDashboard />,
    },
    {
      path: "payment-status/:id1/:id2",
      element: <PaymentSuccess />,
    },
    {
      path: "restaurant-detail-test/:id",
      element: <CustomerDashboardTest />,
    },
  ],
};

export default CustomerRoutes;
