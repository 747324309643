import { createSlice } from "@reduxjs/toolkit";
import { GetPermissionsListAction, GetRolesListAction, UpdatePermissionsAction } from "./action";

export const initialState = {
  rolesList: {
    loading: false,
    data: null,
  },
  permissionsList: {
    loading: false,
    data: null,
  },
  permissionsUpdate: {
    loading: false,
    error: null,
  },
};

export const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //Roles List reducer
    builder.addCase(GetRolesListAction.pending, (state) => {
      state.rolesList.loading = true;
    });
    builder.addCase(GetRolesListAction.fulfilled, (state, action) => {
      state.rolesList.loading = false;
      state.rolesList.data = action.payload.data;
    });
    builder.addCase(GetRolesListAction.rejected, (state) => {
      state.rolesList.loading = false;
      state.rolesList.data = [];
    });
    //Permission List reducer
    builder.addCase(GetPermissionsListAction.pending, (state) => {
      state.permissionsList.loading = true;
    });
    builder.addCase(GetPermissionsListAction.fulfilled, (state, action) => {
      state.permissionsList.loading = false;
      let permissionsObject = {};
      let permissionsArray = [];
      action.payload.data?.forEach((val) => {
        permissionsObject[`${val.name.split("_")[1]}`] = {
          ...(permissionsObject[`${val.name.split("_")[1]}`] ?? []),
          [`${val.name.split("_")[0]}`]: val.name.split("_")[0],
        };
      });

      if (Object.keys(permissionsObject).length > 0) {
        Object.keys(permissionsObject).forEach((key) => {
          permissionsArray.push({
            name: key,
            ...(permissionsObject[`${key}`] ?? []),
          });
        });
      }
      state.permissionsList.data = permissionsArray;
    });
    builder.addCase(GetPermissionsListAction.rejected, (state) => {
      state.permissionsList.loading = false;
      state.permissionsList.data = [];
    });
    //Permissions update reducer
    builder.addCase(UpdatePermissionsAction.pending, (state) => {
      state.permissionsUpdate.loading = true;
    });
    builder.addCase(UpdatePermissionsAction.fulfilled, (state, action) => {
      state.permissionsUpdate.loading = false;
      const index = state.rolesList?.data?.findIndex((val) => val.id === action.payload.data.id);
      if (index !== -1) {
        state.rolesList?.data?.splice(index, 1, action.payload.data);
      }
      state.permissionsUpdate.error = null;
    });
    builder.addCase(UpdatePermissionsAction.rejected, (state, action) => {
      state.permissionsUpdate.loading = false;
      state.permissionsUpdate.error = action.payload.errors;
    });
  },
});

export default settingSlice;
