import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import AuthFooter from "src/components/cards/AuthFooter";

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => (
  <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
    <Box sx={{ flexGrow: 1 }}>
      <Outlet />
    </Box>
    <Box sx={{ py: 2 }}>
      <AuthFooter />
    </Box>
  </Box>
);

export default MinimalLayout;
