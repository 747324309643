import { ArrowLeftOutlined } from "@ant-design/icons";
import { AppBar, Box, Button, Container, Stack, Toolbar, Typography, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import AnimateButton from "src/components/@extended/AnimateButton";
import LogoSection from "src/components/Logo";
import appUtils from "src/utils/appUtils";

const ContactUs = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const appBar = {
    position: "fixed",
    elevation: 0,
    sx: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      background: "transparent",
    },
  };
  let mail = 'client-support@tsubomi.co.jp';
  
  return (
    <Container
      maxWidth="lg"
      sx={{ display: "flex", flexDirection: "column", width: "100%", minHeight: "100vh" }}
      disableGutters
    >
      <AppBar {...appBar}>
        <Toolbar disableGutters sx={{ position: "relative" }}>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              opacity: 0.7,
              backgroundImage: "linear-gradient(to left, red ,white)",
            }}
          ></Box>
          <Container maxWidth="lg" sx={{ display: "flex", width: "100%" }}>
            <Box sx={{ width: "100%", ml: 1, display: "flex", alignItems: "center" }}>
              <LogoSection />
            </Box>
            <Box
              sx={{
                width: "100%",
                ml: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <AnimateButton>
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<ArrowLeftOutlined style={{ fontSize: 15 }} />}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  {t("back")}
                </Button>
              </AnimateButton>
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Box component="main" sx={{ width: "100%", flexGrow: 1, p: 3 }}>
        <Stack direction="column" spacing={2}>
          <Typography variant="h3" textAlign={"justify"}>
            お問い合わせ先
          </Typography>
          <Typography variant="body1" textAlign={"justify"}>
            株式会社ＴＳＵＢＯＭＩお客様窓口
          </Typography>
          <Typography variant="body1" textAlign={"justify"}>
            メール ： {mail}
          </Typography>
          <Typography variant="body1" textAlign={"justify"}>
            受付時間 ：10:00～18:00（土日祝・当社夏季休業日及び年末年始を除く）
          </Typography>
        </Stack>
      </Box>
    </Container>
  );
};

export default ContactUs;
